@tailwind base;
@tailwind components;
@tailwind utilities;

.footer .details .card-wrap div > .image-container {
    @apply smob1:!w-[20%] smob1:!max-w-[88px]
}

.footer .details .card-wrap div > .details {
    @apply smob1:!w-[65%]
}

.footer .details .card-wrap,
.footer .details .card-wrap > div {
    @apply smob1:flex-wrap
}

.footer .details .card-wrap > div {
    @apply overflow-clip smob1:px-6 smob1:pt-8 smob1:pb-12 smob1:justify-center smob1:h-[250px]
}


.footer .details .card-wrap > div .details > div {
    @apply smob1:flex-wrap smob1:w-full
}
