@tailwind base;
@tailwind components;
@tailwind utilities;

.servicepage-wrapper {
    aspect-ratio: 11/13;
    transition: background-color 0.5s;
    background-color: rgb(255, 255, 255);
    @apply flex flex-col justify-between
    
}
.servicepage-wrapper:hover {
    background-color: rgb(245, 245, 245);
}

.servicepage-wrapper,
.servicepage-wrapper .icon {
    @apply relative
}

.servicepage-wrapper .icon > *:not(video) {
    @apply absolute top-0 z-10
}

.servicepage-wrapper .btn-wrapper .onHover > svg {
	transform: rotate(88deg);
    transition: transform 0.5s;
}

.servicepage-wrapper .title,
.servicepage-wrapper .descr {
    @apply overflow-hidden text-ellipsis
}

/* .servicepage-wrapper .btn-wrapper {
    @apply w-fit absolute left-[64px] bottom-[64px]
} */