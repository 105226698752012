@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
	font-family: 'Outfit';
	src: local('Outfit'), url('./fonts/Outfit.ttf');
}

body {
	margin: 0;
	font-family: 'Outfit',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  width: 100wv;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

main {
  width: 100%;
}

.title-7xl {
  @apply text-7xl font-bold
}

.title-7xl > span {
  @apply ml-4
}

.gradient-text {
	background: linear-gradient(141deg, #2097f2, rgb(255 59 59)); /* Define your gradient */
	-webkit-background-clip: text; /* Use background as text color */
	-webkit-text-fill-color: transparent; /* Make the text fill color transparent */
	background-clip: text; /* Standard background clip */
	text-fill-color: transparent; /* For non-webkit browsers */
}

.menu-container {
    @apply sdesk1:py-6
}


.menu-mobile {
	@apply tab1:!h-8
}

.topmenu {
	@apply tab1:!hidden
}

.home-page .paragraph,
.home-page .descr {
	@apply stab1:!max-w-[90%]
}

.paragraph,
.descr {
  @apply text-xl text-[#7B7386] stab1:!text-xl smob1:!text-xl smob1:!max-w-full
}

.paragraph-white {
  @apply text-xl text-white
}

.content .descr {
  @apply max-w-[80%] text-lg text-[#7B7386]
}

.home-page .ContactUsCTA2-container > div {
	@apply ssdesk1:py-32
}

.cntnt-wrapper {
  @apply tab1:w-full mob1:w-full
}

.cta-btn {
  @apply 
  	des:text-xl 
	smob1:text-sm 
	stab1:text-xl 
	bg-[#5B62FF] 
	w-fit 
	px-8 
	py-4 
	text-[#fff] 
	font-bold rounded-3xl
}

.pointer {
  @apply bg-purple-sec w-3 h-3 rounded-sm shadow-[0px_1px_12px_0px_rgba(203,0,221,0.30)] tab1:w-2 tab1:h-2
}

.pointer:first-child {
  @apply mob1:!mr-4 tab1:!mr-4
}

.pointer:last-child {
  @apply mob1:!ml-4 tab1:!ml-4
}

.sub-title .content{
  @apply tab1:text-sm mob1:text-sm
}

.home-page .service-container > div,
.home-page .aboutUs-container > div {
  @apply pb-48 first-letter:ssdesk1:px-12 ssdesk1:pb-40 stab1:px-8 smob1:px-4;
}

.home-page .Project-container {
	background: linear-gradient(180deg, #f1edfd 5%, white);
}

.home-page .Project-container > div {
	/* @apply pt-44 first-letter:ssdesk1:px-12 ssdesk1:pt-40 stab1:px-8 smob1:px-4; */
}

.Project-container .project-title {
	transition: opacity 0.5s;
}

.Project-container .content-wrapper,
.Project-container .rfm-marquee-container {
	@apply overflow-clip
}

div, p, span, li, ul, h1, h2, h3, h4, h5, h6, a {
	-webkit-user-select: none; /* line* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

.Project-container .swiper-slide {
	width: calc(100% / 3);
	aspect-ratio:  1/1;
	border-color: #F0ECFC;
}

/* screens:smob1 */
@media screen and (max-width:550px) {
	.Project-container .swiper-slide {
		width: 80%;
	}

	.TeamMember-container .member-wrapper {
		width: 100% !important;
	}
}

@media screen and (min-width:550px) and (max-width:800px) {
	.Project-container .swiper-slide {
		width: 75%;
	}
}

@media screen and (min-width:801px) and (max-width:1024px) {
	.Project-container .swiper-slide {
		width: 50%;
	}
}

/* screens:tab1 */
@media screen and (min-width:550px) and (max-width:1024px) {
	.TeamMember-container .member-wrapper {
		width: calc((100% / 2) - 16px) !important;
	}
}

.Project-container .swiper-slide {
	@apply mr-6 cursor-pointer border-4 rounded-2xl overflow-hidden
}

.Project-container .scrolled-projects::-webkit-scrollbar {
	display: none;
}

.Project-container .scrolled-projects {
	-ms-overflow-style: none;  /* IE and Edge */
  	scrollbar-width: none;  /* Firefox */
}



.project-wrapper .hover-el {
	/* background: linear-gradient(141deg, #2098f24f, #ffbbcc4f); */
	/* background: #208cf2a6 ; */
	background: linear-gradient(141deg, #2097f26e, rgb(255 195 195 / 80%));
	/* background: linear-gradient(141deg, #2098f24f,rgba(3, 3, 3, 0.30)); */
	/* background: rgba(0, 0, 0, 0.30); */
	transition: opacity 0.5s, transform 0.7s;
	/* transition-delay: 0.25s; */
}

.project-wrapper .image-wrapper img {
	transition: filter 1s;
}

.projects-container .project-wrapper {
	@apply aspect-square
}

.aboutUs-container .img-container {
	@apply tab1:h-auto smob1:mb-12 stab1:mb-20 ssdesk1:h-fit ssdesk1:pt-[25%] desk1:h-fit desk1:pt-[25%]
}

.aboutUs-container > div {
  @apply stab1:pb-0 smob1:pb-0;
}

.home-page .service-container {
  background: linear-gradient(4deg, #F0ECFC 0%, #FFF 100%);
}

.home-page .service-container > div {
  @apply ssdesk1:pt-40 smob1:pt-0 stab1:pt-0 stab1:pb-28 smob1:pb-24 smob1:!pt-24
}

.home-page .service-container .goto-services {
	@apply smob1:text-lg
}

.section-title {
  @apply 
  	mob1:!text-3xl 
	mob1:!py-5 
	mob1:max-w-[100%] 
	mob1:mx-auto 
	tab1:!text-4xl 
	tab1:!pt-8 
	tab1:max-w-[90%] 
	tab1:mx-auto
}

.Project-container .section-title,
.LocalAds-container .section-title,
.aboutUs-container .section-title,
.ContactUsCTA2-container .section-title,
.CustomerFeedback-container .section-title { 
  @apply tab1:!ml-0 mob1:!ml-0
}

.LocalAds-container .section-title {
  @apply tab1:!mb-0 mob1:!mb-0
}

.service-wrapper {
	width: calc((100% / 3) - 16px);
}

@media screen and (min-width:1025px) {
	.home-page .service-wrapper { 
		@apply min-h-[460px]
	}
}

.service-wrapper {
  @apply
  	bg-neutral-50
	aspect-square
  	max-w-[32%] 
	border border-[#e0e0e0] 
	rounded-3xl 
	py-12
	px-7
	mb-[4%] 
	stab1:max-w-[100%] 
	stab1:w-[100%] 
	relative

	ssdesk1:max-w-[48%] 

	smob1:max-w-none 
	smob1:w-full 
	smob1:pb-7
	smob1:pt-12
	smob1:px-5

	smob1:h-[440px] 
	smob1:flex
	smob1:flex-col
	smob1:items-center
	stab1:pt-14 
	stab1:pb-9 
	stab1:h-[472px]
}

.service-wrapper > div > .icon {
	background: linear-gradient(141deg, #2098f24f, #ffbbcc4f);
}

.service-wrapper > div  > .icon {
  @apply flex items-center justify-center text-lg w-[40%] max-w-[112px] aspect-square rounded-[20%] mb-8 overflow-hidden smob1:mx-auto
}

.service-wrapper > div > .icon > * {
	@apply w-[60%] h-[60%] text-white
}

.service-wrapper > div  > .icon > img {
  @apply object-cover h-full
}

.service-wrapper > div  > .title {
  @apply
  	subpixel-antialiased
	-tracking-tight
  	text-2xl 
	font-medium
	mb-4
	stab1:text-2xl
	stab1:h-auto 
	smob1:text-2xl
	smob1:text-center 
	smob1:max-w-[80%] 
	smob1:h-auto
	smob1:mx-auto
}

.service-wrapper > div  > .descr {
  @apply mb-14 tab1:mb-10 smob1:text-center 
}

.service-wrapper > div  > .descr {
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
}


.service-wrapper .btn-wrapper > div {
	@apply absolute left-[28px] bottom-[36px] smob1:left-0 smob1:right-0 smob1:mx-auto smob1:w-fit
}

.service-wrapper .btn-wrapper svg {
	transition: transform 0.5s;
	cursor: pointer;
}

.breadcrumb {
	@apply smob1:!text-xs font-medium smob1:!mt-4 smob1:max-w-[90%]
}

.service-wrapper .btn-wrapper .onHover > svg {
	transform: rotate(88deg);
}

.service-wrapper > div  > .btn-wrapper .Icon {
  @apply text-4xl
}

.aboutUs-container {
  background: linear-gradient(166deg, #edeafa 0%, #FFF 100%);
}

.aboutUs-container > div {
  @apply ssdesk1:pt-0
}

.aboutUs-container .content-container {
  @apply 
  	ssdesk1:max-w-[50%] 
	stab1:max-w-full 
	stab1:w-full 
	stab1:mb-16 
	smob1:max-w-full 
	smob1:w-full 
	smob1:mb-24
}

.aboutUs-container .img-container {
  @apply 
  	ssdesk1:absolute 
	ssdesk1:top-0 
	ssdesk1:right-12 
	ssdesk1:max-w-[40%] 
	ssdesk1:h-[500px] 
	smob1:max-w-full 
	smob1:w-full 
	smob1:relative 
	smob1:top-0 
	stab1:max-w-full 
	stab1:w-[70%] 
	stab1:relative 
	stab1:top-12 
	stab1:mx-auto

}

.aboutUs-container .img-container .img-large {
  @apply 
  stab1:relative 
  stab1:right-0 
  smob1:relative 
  smob1:right-0 
  left-0 
  mx-auto
}

.aboutUs-container .paragraph {
  @apply tab1:!max-w-full
}

.aboutUs-container .img-small {
  @apply 
  ssdesk1:bottom-0 
  ssdesk1:left-8 
  stab1:relative 
  stab1:max-w-[50%] 
  stab1:left-[2%] 
  stab1:bottom-[25%] 
  smob1:relative 
  smob1:max-w-[50%] 
  smob1:left-[2%] 
  smob1:bottom-[25%]
}

.contactUsCTA-container .bg-wrapper {
  height: calc((100vw / 16)*3.5) ;
}

.contactUsCTA-container .content .sub-header {
  @apply stab1:text-sm smob1:text-sm smob1:hidden smob1:max-w-[80%] smob1:mb-4
}

.contactUsCTA-container .content .header-white {
	@apply smob1:max-w-[90%] smob1:text-4xl
}

.contactUsCTA-container .cta-btn {
	@apply smob1:mt-12
}

.contactUsCTA-container .content-wrapper {
  @apply stab1:content-center smob1:content-center
}

.contactUsCTA-container .content .header {
  @apply stab1:text-4xl stab1:max-w-[80%] smob1:text-4xl smob1:max-w-[80%]
}

.contactUsCTA-container .bg-wrapper {
  @apply stab1:h-[550px] smob1:h-[550px]
}

.contactUsCTA-container .content-wrapper{
  @apply stab1:px-8 smob1:px-4
}

.ContactUsCTA2-container > div {
  @apply stab1:px-0 smob1:px-0
}

.ContactUsCTA2-container > div {
  @apply stab1:py-24 smob1:py-24
}

.ContactUsCTA2-container .img-large {
  @apply stab1:max-w-full stab1:relative smob1:max-w-full smob1:relative
}

.contactUsCTA-container .grey-vector {
  @apply tab1:top-[-35%] tab1:left-[-25%]
}

.contactUsCTA-container .purple-vector {
	@apply tab1:top-[0%] tab1:left-[30%]
  }


.ContactUsCTA2-container .btn-wrapper,
.LocalAds-container .cta-btn {
  @apply stab1:flex-wrap stab1:mt-12 smob1:flex-wrap smob1:mt-12 
}

.ContactUsCTA2-container .btn-wrapper {
  /* @apply tab1:w-full */
}

.ContactUsCTA2-container .btn-wrapper .cta-btn {
  @apply tab1:mb-4
}

.ContactUsCTA2-container .btn-wrapper .contactDetails {
  @apply tab1:text-sm mob1:text-sm
}

.ContactUsCTA2-container .btn-wrapper .contactDetails span:first-child {
  @apply text-[#5B62FF]
}

.ContactUsCTA2-container > div > div {
  @apply tab1:w-full tab1:max-w-none mob1:w-full mob1:max-w-none
}

.ContactUsCTA2-container > div > div.content-container {
  @apply stab1:px-8 stab1:mb-20 smob1:px-4 smob1:mb-20 desk1:sticky
}

.ContactUsCTA2-container > div > div.img-container,
.ContactUsCTA2-container > div > div.img-container img {
  @apply tab1:rounded-none mob1:rounded-none
}

@media screen and (min-width:751px) and (max-width:1180px) {
  .contactUsCTA-container .bg-wrapper {
    height: calc((100vw / 16)*4.5) !important;
	min-height: calc((100vw / 16)*9) !important;
  }
}

.contactUsCTA-container .content-wrapper {
	@apply content-center
}


@media screen and (min-width:781px) {
  .ContactUsCTA2-container .img-container img {
    width: calc(1400px * (40 / 100) );
    height: calc(1400px * (40 / 100) );
    max-width: unset;
  }  
}

.pointers-container .pointers-wrapper .circle {
  width: calc(1400px * (8 / 100) );
  height: calc(1400px * (8 / 100) ); 
  border-radius: 50%;
  background: linear-gradient(141deg, #2097f26e, rgb(255 195 195 / 80%));
  border: 1px solid #f7f7f7;
  background-size: 200% 200%;
  cursor: default;
  animation: gradientAnimation 10s ease infinite;
}

.pointers-container .pointers-wrapper .circle:hover {
	/* @apply animate-pulse */
}

@keyframes gradientAnimation {
	0% {
	  background-position: 0% 50%;
	}
	50% {
	  background-position: 100% 50%;
	}
	100% {
	  background-position: 0% 50%;
	}
  }

.pointers-container > div,
.CustomerFeedback-container > div,
.TeamMember-container  > div,
.Project-container .header-wrapper,
.blogs-container > div,
.clients-container > div {
  @apply stab1:px-8 smob1:px-4
}

.LocalAds-container > div:not(.section-footer) {
  @apply tab1:px-0 tab1:pb-0 mob1:px-0 mob1:pb-0
}

.LocalAds-container .content-container {
  @apply tab1:px-8 mob1:px-6
}

.LocalAds-container .img-container {
  @apply tab1:mt-24 mob1:mt-24
}

.LocalAds-container .section-footer {
  @apply tab1:flex-wrap tab1:px-8 tab1:bottom-0 mob1:flex-wrap mob1:px-8 mob1:bottom-0
}

.LocalAds-container .section-footer > * {
  @apply tab1:w-full mob1:w-full text-center
}

.LocalAds-container .section-footer hr {
  @apply tab1:mt-4 mob1:mt-4
}

.LocalAds-container .img-container .img-large {
  @apply tab1:w-full tab1:relative tab1:max-w-none tab1:rounded-none tab1:overflow-clip mob1:w-full mob1:relative mob1:max-w-none mob1:rounded-none mob1:overflow-clip
}

.LocalAds-container .img-container .img-large img {
  @apply tab1:rounded-none mob1:rounded-none
}

.Project-container .content-wrapper {
  @apply tab1:pt-14 mob1:pt-14
}

.Project-container > div {
  @apply tab1:pb-28 mob1:pb-28
}

.CustomerFeedback-container > div {
  @apply tab1:py-16 mob1:py-16
}

.TeamMember-container  > div {
  @apply tab1:pt-32 tab1:pb-28
}

.TeamMember-container .content-wrapper {
  @apply tab1:justify-between
}

.TeamMember-container .member-wrapper {
	@apply w-[24%] stab1:w-[48%] smob1:w-[48%]
}

.TeamMember-container .member-wrapper {
	opacity: 0; /* Initially hidden */
    transform: translateY(120px); /* Move down a bit */
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.TeamMember-container .member-wrapper.active {
	opacity: 1; /* Show section */
	transform: translateY(0); /* Move to original position */
}

.TeamMember-container .expert-wrapper {
  @apply tab1:w-[100%] tab1:max-w-none tab1:max-h-none tab1:mb-6 tab1:h-auto tab1:overflow-clip mob1:max-w-none mob1:mb-6 mob1:h-auto mob1:overflow-clip
  flex flex-wrap smob1:max-h-[580px]
}

.pointers-container .pointers-wrapper {
  @apply tab1:w-full tab1:justify-center mob1:w-full mob1:justify-center
}

.review-wrapper {
  @apply tab1:!w-full
}

.review-wrapper .message,
.review-wrapper .details {
  @apply smob1:px-6 smob1:pb-12
}

.TeamMember-container .header-black {
  @apply max-w-[80%] tab1:max-w-[90%]
}

.CustomerFeedback-container {
  background: linear-gradient(139deg, #020779 0%, #33005A 100%);
}

.CustomerFeedback-container .content-wrapper {
  @apply tab1:mt-10 mob1:mt-10
}

.LocalAds-container > div > div {
  @apply tab1:w-full tab1:max-w-none mob1:w-full mob1:max-w-none
}

.LocalAds-container > div div.img-large {
  @apply tab1:relative
}

.home-page .blog-container {
  @apply w-[48%] stab1:!w-[48%] stab1:!h-[489px] smob1:!w-full mob1:!mb-6 !px-0
}

.home-page .blog-container .read-btn {
	width: 100%;
	display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.blogs-container .content-wrapper {
	@apply items-start smob1:mt-7
}

.blog-container > .content-wrapper {
	@apply smob1:!mt-0
}

.home-page .blog-container .banner-wrapper {
  @apply tab1:h-[195px] mob1:h-[195px] 
}

.home-page .blog-container .content-wrapper {
  @apply stab1:!px-6 stab1:!py-9 smob1:!px-6 smob1:!pt-9 smob1:!pb-12
}

.home-page .blog-container .content-wrapper .title {
  @apply tab1:!text-xl mob1:!text-xl tracking-normal subpixel-antialiased leading-snug
}

.swiper-pagination {
 @apply flex items-center justify-center
}

.swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  @apply !w-5 !h-5 !rounded-[50%] !bg-[#cbcbcb] border-2 !border-white 
}

.swiper-pagination-bullet-active {
  @apply !w-7 !h-7 !rounded-[50%] !bg-[#5B62FF] border-[10px] !border-[#240264] !border-double outline-purple-pri outline-2
}

.text-shadow {
    text-shadow: 1px 1px 0 #000, 
                 2px 2px 0 #000, 
                 3px 3px 0 #000, 
                 4px 4px 0 #000;
}

.expert-wrapper {
  width: 100%;
  aspect-ratio: 3/4;
  background: black;
  border-radius: 15px;
  margin-bottom: 30px;
}

.expert-wrapper .image-wrapper {
	@apply h-full
}

.metadata-wrapper > div {
  @apply text-sm flex items-center
}

.metadata-wrapper > div:first-child {
  @apply mr-3
}

.metadata-wrapper .icon {
  @apply mr-1 text-[#5B62FF]
}

.blog-container {
  @apply border border-[#d7d7d7] rounded-xl
}

.clients-container .content-wrapper {
  @apply  w-full mt-12 smob1:mt-4 mb-4 flex flex-wrap justify-between items-center border-[#e9e9e9] rounded-xl border-2
}

.home-page .clients-container > div {
 @apply !pb-[72px]
}

.clients-container .client {
  @apply flex items-center justify-center px-12 py-16  border-0
}

.clients-container .client {
  @apply tab1:w-full mob1:w-full
}

.l-btn-purple {

}

.l-clbtn-purple {

}

.m-btn-purple {
	@apply
		block
		w-max
		px-11
		py-6
		mob1:!px-7
		mob1:!py-4
		tab1:px-8
		tab1:py-4
		bg-purple-pri
		text-white
		tracking-widest
		rounded-full
		text-ellipsis
		overflow-hidden
		text-2xl
		mob1:!text-sm
		tab1:text-xl
}

.m-clbtn-purple {
	@apply
		bg-purple-pri
		text-white
		text-3xl
		mob1:!text-sm
		tab1:text-xl
		rounded-full
		w-fit
		h-fit
		p-6
		tab1:p-5
		flex
		justify-center
		items-center
}

.l-clbtn-purple {
	@apply
		bg-gray-bg-1
		text-black
		text-xs
		mob1:!text-sm
		rounded-full
		w-16
		h-16
		tab1:p-5
		flex
		justify-center
		items-center
    shadow-[0px_1px_8px_1px_rgba(0,0,0,0.2)]
		transition-all
		duration-75
		ease-in
		hover:font-bold
		hover:bg-purple-pri
		hover:text-lgray
}

.l-clbtn-purple.active {
	@apply
		bg-purple-pri
		text-white
		text-xs
		mob1:!text-sm
		rounded-full
		w-16
		h-16
		tab1:p-5
		flex
		justify-center
		items-center
    shadow-[0px_1px_8px_1px_rgba(0,0,0,0.2)]
		transition-all
		duration-75
		ease-in
		hover:font-bold
		hover:bg-purple-pri
}

.s-btn-purple {

}

.s-btn-blue {

}

.xs-clbtn-purple {
	@apply
		rounded-full
		bg-purple-pri
		flex justify-center
		items-center
		w-12
		h-12
		overflow-hidden
		text-2xl
		text-white
		transition-all
		duration-75
		ease-in
		hover:text-3xl
		hover:bg-purple-bg-1
		hover:text-lgray
}

.header-l {

}

.header-white {
	@apply text-white
}

.header-black {
  @apply  text-black
}

.header-black, .header-white {
	@apply
		block
		text-[3.5rem]
		leading-none
		mob1:text-[1.75rem]
		font-bold
		py-8
		mob1:!py-6
}

.subheader {
	@apply
		header
		flex
		text-black
		tracking-wider
		font-medium
		py-4
		smob1:pb-2
		text-4xl
		mob1:!text-2xl
		tab1:text-3xl
}

.menu-container {
	@apply sticky top-0 bg-white z-50
}

.menunav-container {
	@apply z-50 p-0
}

.menunav-container .menulist {
	@apply border-r-2 border-b-2 border-r-gray-300 border-b-gray-300 p-2 top-0
}

b {
	@apply !font-semibold
}

.paragraph-gray {
	@apply text-lgray1 font-light
}

.paragraph-white {
	@apply text-white
}

.paragraph-center {
	@apply text-lgray1
}

.paragraph-gray ,.paragraph-white, .paragraph-center {
	@apply text-2xl leading-[2.1rem] mob1:!text-lg tab1:text-xl 
}

.title {
	@apply text-black font-semibold text-2xl tracking-widest mob1:text-xl
}

.description {
	@apply text-black font-light text-lg mob1:text-sm
}

.container-header-white {
	@apply text-white
}

.container-header-black {
	@apply text-black
}

.container-header-black, .container-header-white {
	@apply font-semibold text-xl mob1:text-xs
}

.clientTalk-container .bubble1 {
	background: linear-gradient(141deg, #a1ffeb, rgb(174, 212, 247)); /* Define your gradient */
}

.clientTalk-container .bubble3 {
	background: linear-gradient(141deg, #a1cdff, rgb(152, 189, 238)); /* Define your gradient */
}

.clientTalk-container .bubble2 {
	background: linear-gradient(141deg, #e5b5fc, rgb(255, 194, 245)); /* Define your gradient */
}

@layer components {

	.input-short,
	.input-long,
	.input-textarea {
		@apply rounded-xl p-6 tracking-wider text-black placeholder-gray-bg-2 tab1:p-5 tab1:text-sm;
	}

	.input-short {
		@apply w-1/2
	}

	.input-long {
		@apply w-full
	}

	.input-textarea {
		@apply w-full h-full
	}
}