@tailwind base;
@tailwind components;
@tailwind utilities;

.banner1-container {
    @apply desk1:px-20 flex justify-center flex-wrap mx-auto relative z-10 overflow-x-clip overflow-y-visible desk1:mt-1 desk1:pt-[124px] desk1:pb-[112px] ssdesk1:pt-28 ssdesk1:px-12 stab1:pt-8 stab1:pb-[40%] stab1:px-8 smob1:pt-8 smob1:pb-0 smob1:px-4
}

.content-container {
    @apply desk1:max-w-[1400px] w-full z-20
}

.banner1-container .cntnt-wrapper {
    @apply desk1:max-w-[40%] ssdesk1:max-w-[50%] mob1:max-w-[80%] max-w-[60%]
}

.banner1-container .main-title {
    @apply stab1:text-5xl stab1:pt-5 stab1:max-w-[550px] smob1:text-4xl smob1:pt-3 smob1:max-w-[100%] smob1:leading-[3rem]
}

.banner1-container .bg-wrapper {
    @apply stab1:top-[20%] stab1:max-w-[60%] smob1:relative smob1:w-full smob1:max-w-none smob1:top-[-9rem] smob1:right-[-5rem] 
}

.goto-services {
    @apply text-2xl underline mt-8 stab1:mt-12
}

.banner-home-video {
    @apply w-full h-auto object-cover max-w-[100%];
    clip-path: polygon(11% 49.5%, 100% 94%, 100% 5%);
}