@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
	font-family: 'Outfit';
	src: local('Outfit'), url('./fonts/Outfit.ttf');
}

@media screen and (max-width:1024px){
	.footer .element-wrap {
		@apply !flex-col
	}
}