@tailwind base;
@tailwind components;
@tailwind utilities;

.cta-btn {
    @apply 
  	des:text-xl 
	smob1:text-sm 
	stab1:text-xl 
	bg-[#208BF2] 
	w-fit 
	px-8 
	py-4 
	text-[#fff] 
	font-bold rounded-3xl
    flex 
    items-center
}

.callus-btn .descr {
    @apply 
    ml-3
}

.aboutus-page .breadcrumb {
    @apply stab1:hidden
}

.aboutus-page .hero.section .subheader {
    @apply tab1:justify-center
}

.aboutus-page .hero.section .list-why {
    @apply 
        stab1:flex 
        stab1:gap-x-11
        stab1:gap-y-4 
        stab1:flex-wrap 
        stab1:justify-center
        stab1:mt-8
}

/* screens:tab1 */
@media screen and (min-width:550px) and (max-width:1024px) {
	.aboutus-page .pointers-container .pointers-wrapper {
        width: calc((100% / 3) - 16px) !important;
        margin-bottom: 0rem;
        justify-content: center;
        gap: 12px;
    }

}

@media screen and (max-width:1024px) {
    .aboutus-page .pointers-container .pointers-wrapper .content {
        @apply text-center
    }

    .aboutus-page .pointers-container .pointers-wrapper {
        @apply gap-3;
    }

    .aboutus-page .clientTalk-container {
        @apply mt-0 mb-32
    }

    .aboutus-page .clients-container .content-wrapper {
        @apply mt-8
    }

    .aboutus-page .clients-wrapper {
        @apply pb-24
    }

    .aboutus-page .TeamMember-container > div {
        @apply !pt-0
    }
    .aboutus-page .ContactUsCTA2-container > div {
        @apply pb-0
    }
}

@media screen and (max-width:550px) {
    .aboutus-page .hero.section .image {
    }
}


.aboutus-page .hero.section .list-why li {
    @apply w-fit
}

.aboutus-page .hero-title,
.aboutus-page .hero.section .header p {
    @apply tab1:text-center
}

.aboutus-page > div {
    @apply sdesk1:px-12 smob1:px-4 stab1:px-8
}

.hero .cta-wrap .cta-btn {
    @apply mr-3
}

.aboutus-page > div > div {
    @apply smob1:px-0 stab1:px-0
}

.hero .strongpoint .title {
    @apply font-medium text-[1.75rem] mb-5 smob1:text-2xl
}

.hero .strongpoint .paragraph-gray {
    @apply mt-5
}

.aboutus-page .ContactUsCTA2-container > div {
    @apply ssdesk1:pb-0
}

.aboutus-page .pointers-container > div {
    @apply smob1:py-24
}

.ContactUsCTA2-container > div > div {
    @apply ssdesk1:w-full ssdesk1:max-w-none
}

.ContactUsCTA2-container .content-container {
    @apply ssdesk1:mb-16
}

.aboutus-page .clientTalk-container {
    @apply ssdesk1:py-16 smob1:pb-20
}

.ContactUsCTA2-container .img-container {
    @apply ssdesk1:h-fit
}

.ContactUsCTA2-container .img-container .img-large {
    @apply ssdesk1:relative
}

.clients-container .client {
    @apply ssdesk1:px-8 ssdesk1:py-12
}

.aboutus-page .clients-container > div {
    @apply tab1:px-0
}

.aboutus-page .pointers-container .pointers-wrapper {
    @apply flex-col;
}

.ContactUsCTA2-container .section-container {
    opacity: 0; /* Initially hidden */
    transform: translateY(120px); /* Move down a bit */
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.ContactUsCTA2-container .section-container.active {
    opacity: 1; /* Show section */
    transform: translateY(0); /* Move to original position */
}