@tailwind base;
@tailwind components;
@tailwind utilities;

.project-container .content-container {
    @apply 
    px-20 
    pt-20
    pb-36 
    flex 
    justify-center 
    tab1:pb-20
    ssdesk1:px-12
    ssdesk1:pb-20
    tab1:p-8
    smob1:p-4
}

.marketing-container {
    background: linear-gradient(45deg, #f12752, #3b27f1);
}

.marketing-container .inner-child {
    background: linear-gradient(66deg, #ec2758, #e800ff);
}

.projectDetail-banner {
    @apply 
    w-full 
    aspect-video 
    rounded-[30px] 
    overflow-hidden 
    mb-24 
    ssdesk1:mb-12
    stab1:mb-20
    smob1:mb-16
}

.projectTitle-container .projectTitle > * {
    @apply
    block 
    text-[70px] 
    mob1:text-[50px] 
    font-bold 
    tracking-wider 
    pb-8
    stab1:pb-12 
    smob1:!pb-6
}

.project-container .projectDescription-container {
    @apply 
    border-y 
    border-gray-border-1 
    py-24 
    ssdesk1:py-16
    tab1:py-12
    smob1:pt-16
    smob1:pb-20
}

.project-container .projectDescription-container > div {
    @apply 
    tab1:justify-start 
    tab1:pl-0 
}

.project-container .projectDescription-container .column-wrap:not(:last-child){
    @apply tab1:mb-10
}

.project-container .projectDescription-container .column-wrap {
    @apply  
    flex 
    flex-row 
    justify-center
    w-[30%]
    max-w-sm 
    tab1:w-full
    tab1:justify-start
}

.project-container .projectDescription-container .icon-wrap {
    @apply mr-4
}

.project-container .descr-wrap .title {
    @apply text-lgray2 text-base
}

.project-container .projectContent-container {
    @apply 
    pt-16 
    pb-12
    ssdesk1:py-12
    tab1:pt-8
}

.project-container .projectContent-container .sideInfo-wrap {
    @apply  
    w-1/3 
    sdesk1:w-full 
    pl-4 
    h-fit
    sdesk1:pl-0 
    pt-10 
    sdesk1:pt-16 
    tab1:!pt-12 
    desk1:max-w-lg
    desk1:sticky
    desk1:top-[-20px]
}

.sideInfo-wrap > div {
    @apply tab1:!px-8 tab1:!py-10
}

.project-container .sideInfo-wrap .rowInfo-wrap.contactdetails > div {
    @apply mb-4
}

