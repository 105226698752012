@tailwind base;
@tailwind components;
@tailwind utilities;

.service-page.page-container {
    @apply overflow-hidden opacity-0 transition-opacity ease-in duration-300
}

.service-page.page-container.show {
    @apply opacity-100
}

.service-container .service {
    @apply overflow-hidden
}

.servicepage-wrapper {
    @apply mob:px-3 py-2
}

@media screen and (min-width:1181px) {
    .service-container .service {
        width: calc((100% / 3) - 16px);
        aspect-ratio: 2/3;
    }    

}

@media screen and (min-width: 480px)and (max-width:896px) {
    .service-container .service {
        width: 100%;
        aspect-ratio: unset;
    }    

    .servicepage-wrapper {
        padding-top: 1.5rem;
        aspect-ratio: unset;
    }
}

@media screen and (max-width:479px) {
    .service-container .service {
        width: 100%;
        aspect-ratio: unset;
    }    

    .servicepage-wrapper .btn-wrapper {
        position: absolute;
        left: 28px;
        bottom: 28px;
    }
}

@media screen and (min-width:896px) and (max-width:1180px) {
    .service-container .service {
        width: calc((100% / 2) - 16px);
    }    
}

/* @media screen and (min-width:621px) and (max-width:750px) {
    .service-container .service {
        width: calc((100% / 2 ) - 16px);
    }    
}

@media screen and (max-width:620px) {
    .service-container .service {
        width: calc((100%));
    }
} */

.servicepage-wrapper {
    @apply  
    w-full 
    border 
    border-[#B0B0B0] 
    rounded-3xl 
    pt-16 
    pb-12 
    px-16 
    stab1:px-8 
    stab1:pt-12  
    smob1:px-4
    smob1:pt-8    
    h-full
}

.servicepage-wrapper .btn-wrapper {
    @apply stab1:bottom-8 stab1:left-8
}

.marketing-container .content-wrap {
    @apply ssdesk1:px-12 stab1:px-8 smob1:px-4
}

.marketing-container .descr-wrap {
    @apply 
    des:w-[50%] 
    sdesk1:w-[100%] 
    sdesk1:mb-32 
    smob1:!mb-20
    pt-10
}

.marketing-container .img-wrap {
    @apply 
    sdesk1:mb-12 
    des:w-[45%]
    sdesk1:w-[100%] 
    rounded-[30px] 
    overflow-hidden 
    aspect-square 
    des:aspect-video 
    mob:aspect-video
}

.service-page .marketing-container .content-wrap {
    @apply 
    flex 
    flex-wrap 
    justify-between 
    max:w-[1400px]
    smob1:!px-4 
    stab1:!px-9 
    ssdesk1:!px-12
    px-24 
    max:px-20
    z-[1] 
    py-32
    smob1:py-12
}

@media screen and (min-width:1564px) {
    .service-page .marketing-container .content-wrap {
        @apply !px-0
    }
}


.descr-wrap .main-title {
    @apply smob1:pt-6
}

.blog-container .blog {
    @apply h-[750px] relative smob1:h-[600px]
}

.blog-container .blog .descr > p {
    width: 100%;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.blog-container .blog > div {
    @apply h-full
}

.blog-container .blog .readmore-btn {
    @apply absolute bottom-8 left-8 smob1:left-4
}

@media screen and (min-width:1200px) {
    .blog-container .blog {
        width: calc((100% / 3) - 16px);
    }    
}

@media screen and (min-width:780px) and (max-width:1199px) {
    .blog-container .blog {
        width: calc((100% / 2) - 16px);
    }    
}

@media screen and (max-width:780px) {
    .blog-container .blog {
        width: calc(100%);
    }    
}

.blog-container {
    @apply flex flex-col items-center px-20 ssdesk1:px-12 stab1:px-8 smob1:px-4
}
