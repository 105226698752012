@tailwind base;
@tailwind components;
@tailwind utilities;

.counter-wrap .parent-container {
    @apply mt-8 
}

.square-count-container {
    @apply w-full flex flex-wrap gap-x-4 items-center
}

.square-count-container .icon {
    @apply flex flex-wrap gap-y-2
}

.square-count-container .square-box.active {
    background: linear-gradient(125deg, #dd0013, #fffc67);
}

.square-count-container .square-box {
    background: linear-gradient(125deg, #0b6f00, #00ec8d);
}

.square-count-container .square-box {
    @apply w-6 h-6 mr-2 rounded-sm opacity-100 transition-all 
}

.square-count-container .square-box:hover {
    @apply cursor-pointer
}

.square-count-container .descr {
    @apply text-xl font-medium font-mono 
}

/* .square-count-container .square-box:hover {
    @apply
} */