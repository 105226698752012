@tailwind base;
@tailwind components;
@tailwind utilities;

.menubar {
    z-index: 20;
}

.menubar-container {
    @apply h-screen fixed
}

.menulist {
    @apply 
    tab1:w-[80%] 
    tab1:bg-white 
    tab1:h-full 
    tab1:relative 
    tab1:top-[2px]
}

.menulist a {
    @apply text-left rounded-md mob1:px-2 mob1:py-4 tab1:px-4 tab1:py-6
}

.menubar {
    @apply 
    tab1:!sticky 
    tab1:top-0 
    tab1:bg-white 
    tab1:border-b-2 
    tab1:border-b-[#f9f9f9]
}