@tailwind base;
@tailwind components;
@tailwind utilities;

.page-header {
    @apply max-w-[70%] smob1:!max-w-[90%] smob1:px-4 smob1:!pt-12 smob1:text-center mx-auto text-center
    
}

.serviceDetail-container {
    @apply 
    w-full 
    mx-auto 
    flex 
    justify-center 
    pt-20 
    pb-36 
    tab1:pb-20 
    px-20 
    ssdesk1:px-12
    tab1:p-8
    smob1:px-0
    smob1:pt-4
    smob1:pb-16
}

.serviceDetail-page {
    @apply w-full opacity-0
}

.serviceDetail-page.show {
    @apply opacity-100 transition-opacity ease-in duration-300
}

.serviceDetail-container .detail-container {
    @apply smob1:!text-[#323239]
}

@media screen and (min-width:1181px) {
    .serviceDetail-container .detail-container {
        width: 70%;
        max-width: calc(100% - (30% + 2rem));
    }

    .serviceDetail-container .sideInfo-container {
        @apply w-[30%] max-w-[450px]
    }
}

.serviceDetail-container .detail-container .img-wrap {
    @apply stab1:mb-16 smob1:mb-16 smob1:rounded-none
}

.service-detail-swiper .swiper-slide-visible .swiper-title {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.5s, transform 1s;
}

.service-detail-swiper .swiper-slide-visible.swiper-slide-next .swiper-title {
    opacity: 1;
    transform: translateY(0);
}

.service-detail-swiper .swiper-slide-visible .swiper-title {
    @apply tab1:!opacity-100 tab1:!translate-y-0
}

.serviceDetail-container .swiper-container .swiper-slide {
    aspect-ratio: 3/2;
}

.serviceDetail-container .sideInfo-container {
    @apply h-fit sticky top-28 sdesk1:w-full ssdesk1:flex ssdesk1:justify-between ssdesk1:mt-6 smob1:!px-4 smob1:mt-8
}

.serviceDetail-container .sideInfo-container > div {
    @apply ssdesk1:w-[49%] ssdesk1:px-7 ssdesk1:py-16 ssdesk1:mb-0 stab1:px-7 stab1:py-16
}

.serviceDetail-container .paragraph {
    @apply tab1:!w-full text-lgray1 pb-8 smob1:!pb-4
}

.image-pointer-container .pointers-container {
    @apply 
    des:!w-1/2
    px-8 
    pb-12 
    pt-12
}

.pointers-container.d-list {
    @apply tab1:!px-0 tab1:!py-4 stab1:flex
    /* @apply smob1:border-2 smob1:border-gray-300 smob1:rounded-md smob1:!py-2 */
}

.pointers-container.d-list > ul {
    @apply mob1:!w-full 
}

.pointers-container.d-list li {
    /* @apply 
    smob1:border-2
    smob1:border-gray-200 
    smob1:rounded-md 
    smob1:mb-4 
    smob1:flex 
    smob1:flex-wrap 
    smob1:items-center */
}

.serviceDetail-page .clients-container .content-wrapper {
    @apply smob1:!border-r-0 smob1:!border-l-0 smob1:!rounded-none
}

.serviceDetail-page .contactUsCTA-container {
    @apply mb-12 smob1:!mb-0
}

.serviceDetail-page .rfm-marquee-container {
    @apply tab1:!py-6
}

.serviceDetail-page .contactUsCTA-container img {
    scale: 1.2;
} 

.serviceDetail-page li a {
    @apply underline text-[#208bf2]
}

.serviceDetail-page .contactUsCTA-container .bg-wrapper {
    border-radius: 30px;
}

.serviceDetail-page .contactUsCTA-container .bg-wrapper {
    @apply smob1:rounded-none
}
.serviceDetail-page .contactUsCTA-container .content-wrapper {
    @apply px-12 smob1:px-8
}

.serviceDetail-page .contactUsCTA-container .content-wrapper .cta-btn {
    @apply mt-8
}

.serviceDetail-page .header-white {
    @apply text-5xl
}

.pointers-container {
    @apply w-full
}

.serviceDetail-container .pointers-container:not(.d-list) {
    @apply
    flex 
    flex-wrap 
    content-baseline 
    items-start  
    overflow-clip 
    tab1:w-full 
    tab1:aspect-auto
    tab1:px-4
    tab1:!pt-10
    tab1:!pb-14
}

.serviceDetail-container .clients-container .client {
    @apply tab1:py-4
}

.serviceDetail-container .pointers-container .title {
    @apply  
    pb-6 
    block 
    text-3xl 
    w-full 
    smob1:text-2xl 
    smob1:pb-4 
    smob1:text-center 
    smob1:max-w-[80%] 
    smob1:mx-auto
}

.serviceDetail-container .image-pointer-container {
    /* @apply my-12 */
}

.serviceDetail-container .image-col-wrap {
    @apply tab1:mb-8 smob1:mb-4
}

.serviceDetail-container .faq-container {
    @apply tab1:mt-0
}

.serviceDetail-container .faq-title {
    @apply smob1:text-sm
}

.serviceDetail-container .faq {
    @apply smob1:pb-0
}

.serviceDetail-container .servicesInfo-container .title,
.serviceDetail-container .workingHInfo-container .title {
    @apply smob1:text-2xl
}

.serviceDetail-container .servicesInfo-container .name,
.serviceDetail-container .servicesInfo-container .icon {
    @apply smob1:text-base
}

.serviceDetail-container .workingHInfo-container .datetime {
    @apply smob1:text-sm
}

.serviceDetail-container .hourInfo-container > div {
    @apply smob1:p-4
}

.servicesInfo-container, 
.workingHInfo-container {
    @apply smob1:!px-6 smob1:!py-12
}


