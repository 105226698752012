@tailwind base;
@tailwind components;
@tailwind utilities;

.projects-page.page-container {
    @apply pb-36 tab1:pb-20 opacity-0 transition-opacity ease-in duration-300
}

.projects-page.page-container.show {
    @apply opacity-100
}

@media screen and (min-width:1181px) {
    .projects-container .cell-container {
        width: calc((100% / 3) - 16px);
        margin: 8px;
    }    

}

@media screen and (min-width:870px) and (max-width:1180px) {
    .projects-container .cell-container {
        width: calc((100% / 3) - 16px);
        margin: 8px;
    }    
}

@media screen and (min-width:621px) and (max-width:870px) {
    .projects-container .cell-container {
        width: calc((100% / 2 ) - 16px);
        margin: 8px;
    }    
}

@media screen and (max-width:620px) {
    .projects-container .cell-container {
        width: calc((100%));
        margin-bottom: 16px;
    }
}